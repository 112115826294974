import { isFunction } from 'lodash-es';

import { CallbacksObject } from 'app/SDKBridge/Bridge';

import { NotAFunctionException, NotOverridableException } from './exceptions';
import { IFrameWrapper } from './IFrameWrapper';
import MessageListener from './MessageListener';
import { Options } from './reducers/page';
import ReduxConnectedClass from './redux-sdk';
import { AdditionalParams } from './services/navigation';

export type Dispatchers = {
    login: (userToken: string) => void;
    logout: () => void;
    setOptions: (options: Options) => void;
    navigate: (
        route: string,
        seedData?: Record<string, any>,
        additionalParams?: AdditionalParams,
    ) => void;
    back: () => void;
    forward: () => void;
    registerCallback: (
        action: string,
        callback: (...args: Array<any>) => any,
    ) => void;
    unregisterCallback: (action: string) => void;
    disableMessaging: () => void;
};

export type State = {
    isLoggingOut: boolean;
    isLoggingIn: boolean;
};

/** List of actions for which we can define a callback */
const AVAILABLE_ACTIONS: CallbacksObject = {
    open_business: true,
    subscribe: true,
    business_created: true,
    business_advanced_settings_updated: true,
    business_infos_updated: true,
    business_contact_updated: true,
    business_description_updated: true,
    business_attributes_updated: true,
    business_links_updated: true,
    business_open_hours_updated: true,
    business_more_hours_updated: true,
    business_specific_hours_updated: true,
    business_address_updated: true,
    business_logo_updated: true,
    business_cover_updated: true,
    business_photos_updated: true,
    business_categories_updated: true,
    business_menu_updated: true,
    business_custom_fields_updated: true,

    error: true,
    no_eligible_business_click: true,
    pm_view_go_to_edit_click: true,
    pm_view_go_to_partner_connection_click: true,
    no_business_click: true,
};

class PartooSDK extends ReduxConnectedClass<State, Dispatchers> {
    messageListener: MessageListener;

    elementId: string;

    isBeingDestroyed = false;

    destroyCallback: null | (() => void) = null;

    constructor(messageListener: MessageListener, elementId: string) {
        super();
        this.messageListener = messageListener;
        this.elementId = elementId;
    }

    onStateChange(prevState: State, newState: State): void {
        if (newState.isLoggingOut) {
            if (!this.isBeingDestroyed) {
                setTimeout(() => {
                    this.messageListener.destroyListener();
                    this.dispatchers.disableMessaging();
                    IFrameWrapper.remove(this.elementId);
                    // @ts-ignore
                    delete this.messageListener;
                    // @ts-ignore
                    delete this.elementId;

                    if (this.destroyCallback) {
                        this.destroyCallback();
                    }
                }, 200);
                this.isBeingDestroyed = true;
            }
        }
    }

    /**
     * Set display options for intercom & the app
     * @param options Options
     * */
    setOptions = (options: Options): void =>
        this.dispatchers.setOptions(options);

    /**
     * Log user inside Partoo Application using either an API key or a connection token
     * ⚠️ Using an API key for logging is a deprecated behaviour
     *
     * @param userToken string Connection token or API key
     */
    login = (userToken: string): void => this.dispatchers.login(userToken);

    /**
     * Go to a given route inside the Partoo App
     *
     * @param route
     * @param seedData
     * @param additionalParams
     */
    navigate = (
        route: string,
        seedData?: Record<string, any>,
        additionalParams?: AdditionalParams,
    ): void => this.dispatchers.navigate(route, seedData, additionalParams);

    /**
     * Go back to the previous page inside Partoo App
     */
    back = (): void => this.dispatchers.back();

    /**
     * Go forward to the next page inside Partoo App (if we went back)
     */
    forward = (): void => this.dispatchers.forward();

    /**
     * Define a callback for Partoo App action.
     * If the action does not exists it raises NotOverridableException.
     * If the callback given is not a function, it raises NotAFunctionException.
     *
     * @param action string An event that can occur inside Partoo App
     * @param callback Function Callback to trigger when the action occurs
     * @throws NotOverridableException|NotAFunctionException
     */
    on = (action: string, callback: (...args: Array<any>) => any): void => {
        if (!PartooSDK.checkCallbackIsAvailable(action)) {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw new NotOverridableException(
                `The given action ${action} is not available.`,
            );
        }

        if (!isFunction(callback)) {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw new NotAFunctionException(
                'The given callback is not a function.',
            );
        }

        this.dispatchers.registerCallback(action, callback);
    };

    /**
     * Cancel a callback for Partoo App action.
     * If the action does not exists it raises NotOverridableException.
     *
     * @param action string An event that can occur inside Partoo App
     * @throws NotOverridableException
     */
    off = (action: string): void => {
        if (!PartooSDK.checkCallbackIsAvailable(action)) {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw new NotOverridableException(
                `The given action ${action} is not available.`,
            );
        }

        this.dispatchers.unregisterCallback(action);
    };

    destroy = (destroyCallback: null | (() => void) = null): void => {
        this.destroyCallback = destroyCallback;
        this.dispatchers.logout();
    };

    /**
     * Check if it is possible to define a callback for given action
     *
     * @param action {string} Action name
     * @returns {boolean}
     */
    static checkCallbackIsAvailable(action: string): boolean {
        return AVAILABLE_ACTIONS[action];
    }
}

export default PartooSDK;
