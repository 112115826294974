import { delay } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { Saga } from 'app/common/types';

import iframeWrapper from 'sdk/IFrameWrapper';
import { callbacksSelector, urlSelector } from 'sdk/reducers';
import {
    UserStatus,
    UserStatuses,
    messagingIsEnabled,
    updatePage,
    updateUserStatus,
} from 'sdk/reducers/page';

import {
    HandleCallbackCalledAction,
    HandleErrorAction,
    HandlePageChangedAction,
    HandlePageHeightAction,
    handleMessageActions as actions,
    sendDisplayOptions,
    sendGetSizeRequest,
    sendOverriddenActions,
    sendOverrideStyle,
} from './actionCreators';

/**
 * Saga called when user was logged Out
 */
const onUserLoggedOutSaga = function* (): Saga {
    yield put(updateUserStatus(UserStatuses.LOGGED_OUT as UserStatus));
    iframeWrapper.displayLoadingView();
};

/**
 * Saga called when user successfully logged in
 */
const onUserLoggedInSaga = function* (): Saga {
    yield put(updateUserStatus(UserStatuses.LOGGED_IN as UserStatus));
    iframeWrapper.displayIframe();
};

/**
 * Saga called when an HTTP error occurred in Partoo App.
 *
 * @param errorType {number} HTTP error code (400, 404, 500,...)
 */
const onErrorSaga = function* ({ errorType }: HandleErrorAction) {
    iframeWrapper.displayErrorView();
    const callbacks = yield select(callbacksSelector);
    const cb = callbacks.error;

    if (cb) {
        cb(errorType);
    } else {
        // eslint-disable-next-line no-console
        console.warn(`Unexpected error of type ${errorType}`);
    }
};

/**
 * Saga called when the SDK bridge is fully operational
 * in Partoo App (ie. it is possible to post JS message to bridge)
 */
const onIFrameJSHasLoadedSaga = function* (): Saga {
    yield put(messagingIsEnabled());
    yield put(sendOverriddenActions());
    yield put(sendDisplayOptions());
    yield put(sendOverrideStyle());
    yield put(sendGetSizeRequest());
};

/**
 * Saga triggered when an action, for which a callback has been defined,
 * is triggered in Partoo App.
 *
 * @param action {string} Name of the action
 * @param inputs {Object} Data return by the action.
 */
const onCallbackCalledSaga = function* ({
    action,
    inputs,
}: HandleCallbackCalledAction): Saga {
    const callbacks = yield select(callbacksSelector);
    const cb = callbacks[action];

    if (!cb) {
        // eslint-disable-next-line no-console
        console.warn(`No callback defined for ${action}`);
    } else {
        cb(inputs);
    }
};

/**
 * Saga triggered when a JS message containing Partoo App height is received.
 * It adapts the size of the container to this height, wait for 1s and request
 * the Partoo App height again.
 *
 * @param height {number} Partoo App height in pixels
 */
const onPageHeightSaga = function* ({ height }: HandlePageHeightAction): Saga {
    iframeWrapper.resizeContainerToIFrameHeight(height);
    yield delay(1000);
    yield put(sendGetSizeRequest());
};

/**
 * [LEGACY] Saga called when the style has been successfully overridden in Partoo App
 */
const onStyleOverriddenSaga = function* (): Saga {
    // eslint-disable-next-line no-console
    yield call(console.log, 'style overridden');
};

/**
 * Saga called when the iFrame URL has changed.
 *
 * @param url {string}
 */
const onPageChangedSaga = function* ({ url }: HandlePageChangedAction): Saga {
    const previousUrl = yield select(urlSelector);

    if (previousUrl !== url) {
        yield put(updatePage(url));
    }
};

const handleMessagesFromAppSaga = function* (): Saga {
    yield takeEvery(
        actions.HANDLE_USER_LOGGED_OUT_MESSAGE,
        onUserLoggedOutSaga,
    );
    yield takeEvery(actions.HANDLE_USER_LOGGED_IN_MESSAGE, onUserLoggedInSaga);
    yield takeEvery(
        actions.HANDLE_CALLBACK_CALLED_MESSAGE,
        onCallbackCalledSaga,
    );
    yield takeEvery(
        actions.HANDLE_IFRAME_JS_LOADED_MESSAGE,
        onIFrameJSHasLoadedSaga,
    );
    yield takeEvery(actions.HANDLE_PAGE_HEIGHT_MESSAGE, onPageHeightSaga);
    yield takeEvery(
        actions.HANDLE_STYLE_IS_OVERRIDDEN_MESSAGE,
        onStyleOverriddenSaga,
    );
    yield takeEvery(actions.HANDLE_PAGE_CHANGED_MESSAGE, onPageChangedSaga);
    yield takeEvery(actions.HANDLE_ERROR_MESSAGE, onErrorSaga);
};

export default handleMessagesFromAppSaga;
