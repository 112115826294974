import env from 'app/common/services/getEnvironment';

export const COMPETITIVE_BENCHMARK = 'COMPETITIVE_BENCHMARK';
export const WA_DEDICATED_NUMBER_ONBOARDING = 'WA_DEDICATED_NUMBER_ONBOARDING';
export const CONVERSATION_ROUTING = 'CONVERSATION_ROUTING';

export const useFeatureFlagsValues = () => {
    return {
        [COMPETITIVE_BENCHMARK]: !env.isProd() && !env.isSandbox(),
        [WA_DEDICATED_NUMBER_ONBOARDING]: false,
        [CONVERSATION_ROUTING]: !env.isProd(),
    };
};

export type FeatureFlags = ReturnType<typeof useFeatureFlagsValues>;
export type FeatureFlag = keyof FeatureFlags;

export const USE_HOMEPAGE = env.isDev() || env.isStaging();
export const USE_PLACE_ACTION_LINKS = env.isDev() || env.isStaging();
