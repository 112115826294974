import { put, select, take, takeEvery } from 'redux-saga/effects';

import { Saga } from 'app/common/types';

import { CONNECTION_PATH } from 'app/routing/routeIds';

import iframeWrapper from 'sdk/IFrameWrapper';
import {
    selectedBusinessIdSelector,
    startPageSelector,
    urlSelector,
    userStatusSelector,
} from 'sdk/reducers';
import {
    UPDATE_USER_STATUS,
    UserStatus,
    UserStatuses,
    goToNextPage,
    goToPreviousPage,
    resetNavigationHistory,
    resetSeedData,
    updatePage,
    updateSeedData,
    updateUserStatus,
} from 'sdk/reducers/page';
import NavigationService, { LOGOUT_ROUTE } from 'sdk/services/navigation';

import {
    LoginUserAction,
    NavigateToAction,
    navigateActions as actions,
    sendSeedData,
    sendUpdatePage,
} from './actionCreators';

type LoginUserActionWithPage = Omit<LoginUserAction, keyof object> & {
    nextPage: string;
};

/**
 * Saga called to log a user in
 *
 * @param nextPage {string} The page the login should redirect to. Defaults to the startPage.
 * @param tokenOrApiKey {string} Connection Token or API key
 *                               (⚠️ login in using an API key is a deprecated behaviour)
 */
const loginSaga = function* ({ tokenOrApiKey, nextPage }: LoginUserActionWithPage): Saga {
    yield put(updateUserStatus(UserStatuses.LOGGING_IN as UserStatus));
    const page = yield nextPage || select(startPageSelector);
    const selectedBusinessId = yield select(selectedBusinessIdSelector);
    yield put(resetNavigationHistory());
    const loginUrl = NavigationService.generateLoginURL(tokenOrApiKey, page, selectedBusinessId);
    iframeWrapper.updateIframeUrl(loginUrl);
};

/**
 * Saga called to log a user out
 */
const logoutSaga = function* (): Saga {
    yield put(updateUserStatus(UserStatuses.LOGGING_OUT as UserStatus));
    yield put(resetNavigationHistory());
    iframeWrapper.updateIframeUrl(NavigationService.generateURL(LOGOUT_ROUTE));
};

/**
 * Saga to navigate to a given route.
 *
 * @param route {string} Name of the route
 * @param additionalParams {Object}
 * @param seedData {Object}
 */
const navigateToSaga = function* ({ route, additionalParams, seedData }: NavigateToAction): Saga {
    let userStatus = yield select(userStatusSelector);

    // If the user is logged out, check for an existing session
    if (userStatus === UserStatuses.LOGGED_OUT) {
        // @ts-ignore
        yield* loginSaga({
            nextPage: route,
        });
        userStatus = yield select(userStatusSelector);
    }

    // If the user is logging in, wait for it to be logged in
    while (userStatus === UserStatuses.LOGGING_IN) {
        yield take(UPDATE_USER_STATUS);
        userStatus = yield select(userStatusSelector);
    }

    // Update seed data
    if (seedData) {
        yield put(resetSeedData());
        yield put(updateSeedData(seedData));
        yield put(sendSeedData());
    }

    const currentUrl = yield select(urlSelector);
    const selectedBusinessId = yield select(selectedBusinessIdSelector);
    const nextUrl = NavigationService.generateURL(route, additionalParams, selectedBusinessId);

    if (currentUrl !== nextUrl) {
        yield put(updatePage(nextUrl));
        const nextShortUrl = NavigationService.getShortUrlFromFullUrl(nextUrl);
        yield put(sendUpdatePage(nextShortUrl));
    }
};

/**
 * Saga to go back in navigation history.
 */
const goBackSaga = function* (): Saga {
    const currentUrl = yield select(urlSelector);
    yield put(goToPreviousPage());
    const previousUrl = yield select(urlSelector);
    if (previousUrl.includes(CONNECTION_PATH)) return;

    if (currentUrl !== previousUrl) {
        const previousShortUrl = NavigationService.getShortUrlFromFullUrl(previousUrl);
        yield put(sendUpdatePage(previousShortUrl));
    }
};

/**
 * Saga to go forward in navigation history.
 */
const goForwardSaga = function* (): Saga {
    const currentUrl = yield select(urlSelector);

    if (currentUrl.includes(CONNECTION_PATH)) {
        yield put(goToNextPage());
    }

    yield put(goToNextPage());
    const nextUrl = yield select(urlSelector);

    if (currentUrl !== nextUrl) {
        const nextShortUrl = NavigationService.getShortUrlFromFullUrl(nextUrl);
        yield put(sendUpdatePage(nextShortUrl));
    }
};

const navigationSagas = function* (): Saga {
    yield takeEvery(actions.LOGIN_USER, loginSaga);
    yield takeEvery(actions.LOGOUT_USER, logoutSaga);
    yield takeEvery(actions.NAVIGATE_TO, navigateToSaga);
    yield takeEvery(actions.GO_BACK, goBackSaga);
    yield takeEvery(actions.GO_FORWARD, goForwardSaga);
};

export default navigationSagas;
