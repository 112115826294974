import { isEmpty } from 'lodash-es';
import { delay } from 'redux-saga';
import {
    all,
    fork,
    put,
    race,
    select,
    take,
    takeEvery,
} from 'redux-saga/effects';

import { Saga } from 'app/common/types';

import iframeWrapper from 'sdk/IFrameWrapper';
import {
    getPageSize,
    overrideAction,
    overrideStyle,
    rejectAction,
    setOptions,
    sendSeedData as setSeedData,
    setUpdatePage,
} from 'sdk/messages/creators';
import { JsApiMessage } from 'sdk/messages/types';
import {
    callbacksSelector,
    optionsSelector,
    pageSelector,
    seedDataSelector,
} from 'sdk/reducers';
import { CallbacksState, registerCallback } from 'sdk/reducers/callbacks';
import { ENABLE_MESSAGING, Options } from 'sdk/reducers/page';

import {
    SendRegisteredCallbackAction,
    SendUnregisteredCallbackAction,
    sendMessageActions as actions,
} from './actionCreators';

/**
 * Saga to send a JS message to Partoo App.
 * Before posting, check that messaging is enabled (i.e. the SDK bridge is ready to receive message)
 *
 * @param message {Object}
 */
export const sendMessage = function* (message: JsApiMessage): Saga {
    let { messagingEnabled } = yield select(pageSelector);

    // If messaging is disabled, wait for 4s
    if (!messagingEnabled) {
        yield race({
            enable: take(ENABLE_MESSAGING),
            cancel: delay(4000),
        });
        ({ messagingEnabled } = yield select(pageSelector));
    }

    if (messagingEnabled) {
        iframeWrapper.postMessage(message);
    } else {
        // If messaging is not enabled after 4s, log an error.
        // eslint-disable-next-line no-console
        console.error('Messaging not working');
    }
};

/**
 * Saga to send a page size request to Partoo App
 */
const sendGetPageSizeRequestSaga = function* (): Saga {
    yield* sendMessage(getPageSize());
};

/**
 * Saga to send display options to Partoo App (for Partoo Component)
 */
export const sendDisplayOptionsSaga = function* (): Saga {
    const options: Options = yield select(optionsSelector);
    yield* sendMessage(setOptions(options));
};

/**
 * Saga to send all the actions for which a callback has been defined
 * This saga is triggered at login time when the user successfully logged in.
 *
 */
const sendOverriddenActionsSaga = function* (): Saga {
    const callbacks: CallbacksState = yield select(callbacksSelector);
    yield all(
        Object.keys(callbacks).map(action =>
            fork(sendMessage, overrideAction(action)),
        ),
    );
};

/**
 * Send message to indicate that a callback has been defined for a Partoo App action
 *
 * @param action {string} Partoo app action for which we defined a callback
 * @param callback {Function} Callback to call when the action is triggered
 */
const sendRegisteredCallbackSaga = function* ({
    action,
    callback,
}: SendRegisteredCallbackAction): Saga {
    yield put(registerCallback(action, callback));
    yield* sendMessage(overrideAction(action));
};

/**
 * Send message to indicate that a callback has been canceled from a Partoo App action
 *
 * @param action {string} Partoo app action for which we canceled a callback
 */
const sendUnregisteredCallbackSaga = function* ({
    action,
}: SendUnregisteredCallbackAction): Saga {
    yield* sendMessage(rejectAction(action));
};

/**
 * Send next url to display in app
 */
const sendUpdatePageSaga = function* ({ nextUrl }: Record<string, any>): Saga {
    yield* sendMessage(setUpdatePage(nextUrl));
};

/**
 * [LEGACY] Send seed data to fill lab form
 */
const sendSeedDataSaga = function* (): Saga {
    const seedDada: Record<string, any> = yield select(seedDataSelector);

    if (!isEmpty(seedDada)) {
        yield* sendMessage(setSeedData(seedDada));
    }
};

/**
 * [LEGACY] Send a message to override style in Partoo App
 */
const sendOverrideStyleSaga = function* (): Saga {
    yield* sendMessage(overrideStyle());
};

const sendMessageToAppSagas = function* (): Saga {
    yield takeEvery(actions.SEND_DISPLAY_OPTIONS, sendDisplayOptionsSaga);
    yield takeEvery(actions.SEND_OVERRIDDEN_ACTIONS, sendOverriddenActionsSaga);
    yield takeEvery(actions.SEND_UPDATE_PAGE, sendUpdatePageSaga);
    yield takeEvery(actions.SEND_SEED_DATA, sendSeedDataSaga);
    yield takeEvery(actions.SEND_GET_SIZE_REQUEST, sendGetPageSizeRequestSaga);
    yield takeEvery(actions.SEND_OVERRIDE_STYLE, sendOverrideStyleSaga);
    yield takeEvery(
        actions.SEND_REGISTERED_CALLBACK,
        sendRegisteredCallbackSaga,
    );
    yield takeEvery(
        actions.SEND_UNREGISTERED_CALLBACK,
        sendUnregisteredCallbackSaga,
    );
};

export default sendMessageToAppSagas;
