const REGISTER_CALLBACK = 'REGISTER_CALLBACK';

type RegisterCallbackAction = {
    type: 'REGISTER_CALLBACK';
    actionName: string;
    callback: (...args: Array<any>) => any;
};

export type CallbacksState = Record<string, (...args: Array<any>) => any>;

const initialState: CallbacksState = {};

// ACTION CREATOR
export const registerCallback = (
    actionName: string,
    callback: (...args: Array<any>) => any,
): RegisterCallbackAction => ({
    type: REGISTER_CALLBACK,
    actionName,
    callback,
});

// REDUCER
const callbacks = (
    state: CallbacksState = initialState,
    action: RegisterCallbackAction,
): CallbacksState => {
    if (action.type === REGISTER_CALLBACK)
        return { ...state, [action.actionName]: action.callback };
    return state;
};

// SELECTORS
export const actionCallbackSelector = (
    state: CallbacksState,
    actionName: string,
): ((...args: Array<any>) => any) | void => state[actionName];

export default callbacks;
