import { AdditionalParams } from 'sdk/services/navigation';

/**
 * Handle message actions
 */
// ACTION TYPES -------------------------------------------------------------------------
const HANDLE_USER_LOGGED_OUT_MESSAGE = 'HANDLE_USER_LOGGED_OUT_MESSAGE';
const HANDLE_USER_LOGGED_IN_MESSAGE = 'HANDLE_USER_LOGGED_IN_MESSAGE';
const HANDLE_CALLBACK_CALLED_MESSAGE = 'HANDLE_CALLBACK_CALLED_MESSAGE';
const HANDLE_IFRAME_JS_LOADED_MESSAGE = 'HANDLE_IFRAME_JS_LOADED_MESSAGE';
const HANDLE_PAGE_CHANGED_MESSAGE = 'HANDLE_PAGE_CHANGED_MESSAGE';
const HANDLE_PAGE_HEIGHT_MESSAGE = 'HANDLE_PAGE_HEIGHT_MESSAGE';
const HANDLE_STYLE_IS_OVERRIDDEN_MESSAGE = 'HANDLE_STYLE_IS_OVERRIDDEN_MESSAGE';
const HANDLE_ERROR_MESSAGE = 'HANDLE_ERROR_MESSAGE';

// Flow Types
export type HandleUserDisconnectedAction = {
    type: 'HANDLE_USER_DISCONNECTED_MESSAGE';
};

export type HandleUserLoggedInAction = {
    type: 'HANDLE_USER_LOGGED_IN_MESSAGE';
};

export type HandleUserLoggedOutAction = {
    type: 'HANDLE_USER_LOGGED_OUT_MESSAGE';
};

export type HandlePageChangedAction = {
    type: 'HANDLE_PAGE_CHANGED_MESSAGE';
    url: string;
};

export type HandlePageHeightAction = {
    type: 'HANDLE_PAGE_HEIGHT_MESSAGE';
    height: number;
};

export type HandleCallbackCalledAction = {
    type: 'HANDLE_CALLBACK_CALLED_MESSAGE';
    action: string;
    inputs: Record<string, any>;
};

export type HandleErrorAction = {
    type: 'HANDLE_ERROR_MESSAGE';
    errorType: number;
};

// ACTION CREATORS ------------------------------------------------------------------------
export const handleUserIsLoggedIn = (): HandleUserLoggedInAction => ({
    type: HANDLE_USER_LOGGED_IN_MESSAGE,
});

export const handleUserIsLoggedOut = (): HandleUserLoggedOutAction => ({
    type: HANDLE_USER_LOGGED_OUT_MESSAGE,
});

export const handlePageChanged = (url: string): HandlePageChangedAction => ({
    type: HANDLE_PAGE_CHANGED_MESSAGE,
    url,
});

export const handleCallbackCalled = (
    action: string,
    inputs: Record<string, any>,
): HandleCallbackCalledAction => ({
    type: HANDLE_CALLBACK_CALLED_MESSAGE,
    action,
    inputs,
});

export const handleJSHasLoaded = () => ({
    type: HANDLE_IFRAME_JS_LOADED_MESSAGE,
});

export const handlePageHeight = (height: number): HandlePageHeightAction => ({
    type: HANDLE_PAGE_HEIGHT_MESSAGE,
    height,
});

export const handleStyleIsOverridden = () => ({
    type: HANDLE_STYLE_IS_OVERRIDDEN_MESSAGE,
});

export const handleError = (errorType: number): HandleErrorAction => ({
    type: HANDLE_ERROR_MESSAGE,
    errorType,
});

export const handleMessageActions = {
    HANDLE_USER_LOGGED_OUT_MESSAGE,
    HANDLE_USER_LOGGED_IN_MESSAGE,
    HANDLE_CALLBACK_CALLED_MESSAGE,
    HANDLE_IFRAME_JS_LOADED_MESSAGE,
    HANDLE_PAGE_CHANGED_MESSAGE,
    HANDLE_PAGE_HEIGHT_MESSAGE,
    HANDLE_STYLE_IS_OVERRIDDEN_MESSAGE,
    HANDLE_ERROR_MESSAGE,
};

/**
 * Send message actions
 */
// ACTION TYPES -------------------------------------------------------------------------
const SEND_DISPLAY_OPTIONS = 'SEND_DISPLAY_OPTIONS';
const SEND_OVERRIDDEN_ACTIONS = 'SEND_OVERRIDDEN_ACTIONS';
const SEND_GET_SIZE_REQUEST = 'SEND_GET_SIZE_REQUEST';
const SEND_UPDATE_PAGE = 'SEND_UPDATE_PAGE';
const SEND_SEED_DATA = 'SEND_SEED_DATA';
const SEND_OVERRIDE_STYLE = 'SEND_OVERRIDE_STYLE';
const SEND_REGISTERED_CALLBACK = 'SEND_REGISTERED_CALLBACK';
const SEND_UNREGISTERED_CALLBACK = 'SEND_UNREGISTERED_CALLBACK';

export type SendRegisteredCallbackAction = {
    type: 'SEND_REGISTERED_CALLBACK';
    action: string;
    callback: (...args: Array<any>) => any;
};

export type SendUnregisteredCallbackAction = {
    type: 'SEND_UNREGISTERED_CALLBACK';
    action: string;
};

// ACTION CREATORS ------------------------------------------------------------------------
export const sendDisplayOptions = () => ({
    type: SEND_DISPLAY_OPTIONS,
});

export const sendGetSizeRequest = () => ({
    type: SEND_GET_SIZE_REQUEST,
});

export const sendOverriddenActions = () => ({
    type: SEND_OVERRIDDEN_ACTIONS,
});

export const sendUpdatePage = (nextUrl: string) => ({
    type: SEND_UPDATE_PAGE,
    nextUrl,
});

export const sendSeedData = () => ({
    type: SEND_SEED_DATA,
});

export const sendOverrideStyle = () => ({
    type: SEND_OVERRIDE_STYLE,
});

export const sendRegisteredCallback = (
    action: string,
    callback: (...args: Array<any>) => any,
): SendRegisteredCallbackAction => ({
    type: SEND_REGISTERED_CALLBACK,
    action,
    callback,
});

export const sendUnregisteredCallback = (
    action: string,
): SendUnregisteredCallbackAction => ({
    type: SEND_UNREGISTERED_CALLBACK,
    action,
});

export const sendMessageActions = {
    SEND_DISPLAY_OPTIONS,
    SEND_OVERRIDDEN_ACTIONS,
    SEND_GET_SIZE_REQUEST,
    SEND_UPDATE_PAGE,
    SEND_SEED_DATA,
    SEND_OVERRIDE_STYLE,
    SEND_REGISTERED_CALLBACK,
    SEND_UNREGISTERED_CALLBACK,
};

/**
 * Navigate actions
 */
// ACTION TYPES -------------------------------------------------------------------------
const LOGIN_USER = 'LOGIN_USER';
const LOGOUT_USER = 'LOGOUT_USER';
const NAVIGATE_TO = 'NAVIGATE_TO';
const GO_BACK = 'GO_BACK';
const GO_FORWARD = 'GO_FORWARD';

export type LoginUserAction = {
    type: 'LOGIN_USER';
    tokenOrApiKey: string;
};

export type NavigateToAction = {
    type: 'NAVIGATE_TO';
    route: string;
    additionalParams: AdditionalParams;
    seedData?: Record<string, any>;
};

// ACTION CREATORS ------------------------------------------------------------------------
export const loginUser = (tokenOrApiKey: string): LoginUserAction => ({
    type: LOGIN_USER,
    tokenOrApiKey,
});

export const navigateTo = (
    route: string,
    additionalParams: AdditionalParams = {},
    seedData?: Record<string, any>,
): NavigateToAction => ({
    type: NAVIGATE_TO,
    route,
    additionalParams,
    seedData,
});

export const goBack = () => ({
    type: GO_BACK,
});

export const goForward = () => ({
    type: GO_FORWARD,
});

export const logoutUser = () => ({
    type: LOGOUT_USER,
});

export const navigateActions = {
    LOGIN_USER,
    LOGOUT_USER,
    NAVIGATE_TO,
    GO_BACK,
    GO_FORWARD,
};
