import { MessageListenerDispatchers } from './MessageListener';
import {
    handleCallbackCalled,
    handleError,
    handleJSHasLoaded,
    handlePageChanged,
    handlePageHeight,
    handleStyleIsOverridden,
    handleUserIsLoggedIn,
    handleUserIsLoggedOut,
} from './sagas/actionCreators';

const mapDispatchToDispatchers = (
    dispatch: any,
): MessageListenerDispatchers => ({
    callCallback: (action: string, inputs: Record<string, any>) =>
        dispatch(handleCallbackCalled(action, inputs)),
    styleOverridden: () => dispatch(handleStyleIsOverridden()),
    handlePageHeight: (height: number) => dispatch(handlePageHeight(height)),
    handlePageChanged: (url: string) => dispatch(handlePageChanged(url)),
    handleJsLoaded: () => dispatch(handleJSHasLoaded()),
    handleError: (errorType: number) => dispatch(handleError(errorType)),
    handleUserIsLoggedOut: () => dispatch(handleUserIsLoggedOut()),
    handleUserIsLoggedIn: () => dispatch(handleUserIsLoggedIn()),
});

export default [null, mapDispatchToDispatchers];
